<template>
  <section id="dashboard-ecommerce">
    <b-card no-body class="mb-0 p-2">
      <b-row>
        <b-col md="4">
          <b-form-group label="วันที่เริ่ม" label-cols="4">
            <div class="d-flex">
              <flat-pickr
                v-model="startDate"
                class="form-control"
                :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                  time_24hr: true,
                }"
                placeholder="วันที่เริ่มต้น"
              />
              <b-button
                type="button"
                class="btn btn-secondary"
                @click="startDate = null"
              >
                X
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label="วันที่สิ้นสุด" label-cols="4">
            <div class="d-flex">
              <flat-pickr
                v-model="endDate"
                class="form-control"
                :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                  time_24hr: true,
                }"
                placeholder="วันที่สิ้นสุด"
              />
              <b-button
                type="button"
                class="btn btn-secondary"
                @click="endDate = null"
              >
                X
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="refProductListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        :items="data"
        primary-key="id"
        show-empty
        :empty-text="'ไม่พบข้อมูล'"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <!-- <template #cell(index)="data">
         {{ (currentPage - 1) * perPage + data.index + 1 }}
       </template> -->
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">
              แสดง -->
            <!-- {{ dataMeta.to }} -->
            <!-- จาก -->
            <!-- {{ dataMeta.of }} -->
            <!-- รายการ
            </span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <!-- <b-pagination
             v-model="currentPage"
             :total-rows="totalProducts"
             :per-page="perPage"
             first-number
             last-number
             class="mb-0 mt-1 mt-sm-0"
             prev-class="prev-item"
             next-class="next-item"
           >
             <template #prev-text>
               <feather-icon icon="ChevronLeftIcon" size="18" />
             </template>
             <template #next-text>
               <feather-icon icon="ChevronRightIcon" size="18" />
             </template>
           </b-pagination> -->
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormTimepicker,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import invoiceStoreModule from "./invoiceStoreModule";
import store from "@/store";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import axios from "axios";
import { forEach } from "postcss-rtl/lib/affected-props";
import moment from "moment";

export const HTTP = axios.create({
  baseURL: "https://api.teflowservice.com",
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    vSelect,
    BFormGroup,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    flatPickr,
    BFormTimepicker,
    BModal,
    VBModal,
    Ripple,
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      data: null,
    };
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "dashboardadmin";

    const tableColumns = [
      // { key: 'id', label: 'PRODUCT ID', sortable: true },
      { key: "index", label: "ลำดับ" },
      // { key: "user_fullname", label: "ชื่อ-นามสกุล" },
      // { key: "user_role", label: "ประเภทผู้ใช้งาน" },
      // { key: "productColor", label: "โค้ดสี", },
      { key: "product_name", label: "สินค้า" },
      // {
      //   key: "order_type",
      //   label: "ออเดอร์",
      // },
      {
        key: "sum_qty_wholesale",
        label: "จำนวนขายราคาส่ง",
      },
      {
        key: "sum_qty_retail",
        label: "จำนวนขายราคาปลีก",
      },
      // { key: 'actions' },
    ];

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const optionsUser = ref([]);
    const optionsRole = ref([]);
    const selectedUser = ref(null);
    const selectedRole = ref(null);

    const fetchUser = () => {
      store.dispatch("dashboardadmin/getUser").then((response) => {
        if (response !== null) {
          optionsUser.value = [];
          response.data.data.forEach((data) => {
            optionsUser.value.push({
              value: data.userId,
              label: data.username,
            });
          });
        }
      });
    };
    fetchUser();

    const fetchRole = () => {
      store.dispatch("dashboardadmin/getRole").then((response) => {
        if (response !== null) {
          optionsRole.value = [];
          response.data.data.forEach((data) => {
            optionsRole.value.push({
              value: data.roleId,
              label: data.roleName,
            });
          });
        }
      });
    };
    fetchRole();

    const products = ref([]);
    store.dispatch("dashboardadmin/fetchSubinventorys").then((response) => {
      if (response !== null) {
        console.log("response", response);
      }
    });

    return {
      products,
      tableColumns,
      optionsUser,
      optionsRole,
      selectedUser,
      selectedRole,
    };
  },
  created() {
    const user = getUserData();

    const payload = {
      userId: [user.userId],
      roleId: this.selectedRole?.value || null,
      dateFrom: null,
      dateTo: null,
    };
    // data
    HTTP.post("/api/Report/ReportOrder", payload).then((response) => {
      this.data = response.data;
    });
  },
  methods: {
    getData() {
      let startDate = new Date(`${this.startDate}`);
      startDate.setHours(startDate.getHours() + 7);

      let endDate = new Date(`${this.endDate}`);
      endDate.setHours(endDate.getHours() + 7);

      const user = getUserData();

      const payload = {
        userId: [user.userId],
        roleId: this.selectedRole?.value || null,
        dateFrom: startDate || null,
        dateTo: endDate || null,
      };

      HTTP.post("/api/Report/ReportOrder", payload).then((response) => {
        this.data = response.data;
      });
    },

    getUser() {
      HTTP.get("/api/Role/GetByKey?key=" + this.selectedRole.value, {}).then(
        (response) => {
          console.log("response", response);
          response.data.data.forEach((data) => {
            this.optionsUser.push({
              value: data.userId,
              label: data.username,
            });
          });
        }
      );
      /*
      store.dispatch("dashboardadmin/getUser").then((response) => {
        if (response !== null) {
          this.optionsUser = [];
          response.data.data.forEach((data) => {
            this.optionsUser.push({
              value: data.userId,
              label: data.username,
            });
          });
        }
      });
      */
    },
  },
  watch: {
    selectedUser: {
      handler(newValue, oldValue) {
        this.getData();
      },
      deep: true,
    },
    selectedRole: {
      handler(newValue, oldValue) {
        this.getData();
      },
      deep: true,
    },
    startDate: {
      handler(newValue, oldValue) {
        this.getData();
      },
      deep: true,
    },
    endDate: {
      handler(newValue, oldValue) {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
