var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard-ecommerce"}},[_c('b-card',{staticClass:"mb-0 p-2",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"วันที่เริ่ม","label-cols":"4"}},[_c('div',{staticClass:"d-flex"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                time_24hr: true,
              },"placeholder":"วันที่เริ่มต้น"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('b-button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){_vm.startDate = null}}},[_vm._v(" X ")])],1)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"วันที่สิ้นสุด","label-cols":"4"}},[_c('div',{staticClass:"d-flex"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                time_24hr: true,
              },"placeholder":"วันที่สิ้นสุด"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('b-button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){_vm.endDate = null}}},[_vm._v(" X ")])],1)])],1)],1),_c('b-table',{ref:"refProductListTable",staticClass:"position-relative",attrs:{"responsive":"","fields":_vm.tableColumns,"items":_vm.data,"primary-key":"id","show-empty":"","empty-text":'ไม่พบข้อมูล'},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }